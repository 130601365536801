<template>
	<v-card flat color="transparent" class="mx-auto mt-5" rounded="xl">
		<v-card-title class="my-3">
			<span class="text-h5">{{ $t('gdrive.gDriveAccounts') }}</span>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="12" md="6" sm="12">
					<p class="text-body-2 text-justify" style="max-width: 90%">
						{{ $t('gdrive.gDriveEmailsHelp0') }}
					</p>
					<p class="text-body-2 text-justify" style="max-width: 90%">
						{{ $t('gdrive.gDriveEmailsHelp1') }}
					</p>

					<p class="text-body-1 text-justify" style="max-width: 90%">
						<strong>{{ $t('gdrive.gDriveEmailsHelp2') }}</strong>
					</p>
					<p class="text-body-2 text-justify" style="max-width: 90%">
						{{ $t('gdrive.gDriveEmailsHelp3') }}
					</p>
					<p class="text-body-2 text-justify" style="max-width: 90%">
						{{ $t('gdrive.gDriveEmailsHelp4') }}
					</p>
				</v-col>
				<v-col cols="12" md="6" sm="12">
					<v-list v-cloak subheader flat color="transparent">
						<v-subheader>{{ $t('settings.numberOfGDrives') }}: {{ gDriveEmails.length }}/{{ maxGDriveEmails }}</v-subheader>
						<v-list-item v-for="(gDriveEmail, index) in gDriveEmails" :key="index">
							<v-list-item-avatar>
								<v-icon>mdi-google-drive</v-icon>
							</v-list-item-avatar>

							<v-list-item-content>
								<v-list-item-title>{{ gDriveEmail.email }}</v-list-item-title>
							</v-list-item-content>

							<v-list-item-action>
								<v-dialog v-model="dialog" persistent max-width="350">
									<template v-slot:activator="{ on }">
										<v-btn icon color="error" v-on="on">
											<v-icon>mdi-close-circle</v-icon>
										</v-btn>
									</template>
									<v-card rounded="xl">
										<v-card-title>
											<span class="headline">{{ $t('courses.areYouSure') }}</span>
										</v-card-title>
										<v-card-text> You will no longer have access to the courses associated to this account </v-card-text>
										<v-card-actions>
											<v-spacer />
											<v-btn color="blue darken-1" text @click="dialog = false"> No </v-btn>
											<v-btn
												color="error darken-1"
												text
												:loading="loadingRemoval"
												:disabled="loadingRemoval"
												@click="callRemoveGDriveEmail(gDriveEmail)"
											>
												{{ $t('courses.leave') }}
											</v-btn>
										</v-card-actions>
									</v-card>
								</v-dialog>
							</v-list-item-action>
						</v-list-item>
					</v-list>
					<v-subheader>{{ $t('gdrive.addAnotherGDriveEmail') }}</v-subheader>
					<v-form ref="form" v-model="valid">
						<v-text-field
							v-model="newGDriveEmail"
							:label="$t('auth.email')"
							suffix="@gmail.com"
							:rules="rules.newGDriveEmail"
							required
							outlined
							rounded
							prepend-inner-icon="mdi-account-plus"
							hide-details="auto"
							class="pb-3"
						/>
					</v-form>

					<v-btn
						:color="addGDriveEmailButtonColor"
						:loading="action.started && !action.finished"
						:outlined="action.started && !action.finished"
						:disabled="action.started"
						@click="callAddGDriveEmail()"
						rounded
						class="float-right"
					>
						<v-icon left> mdi-plus-circle-outline </v-icon>
						{{ addGDriveEmailButtonText }}
					</v-btn>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'SettingsAddGoogleDrive',
	props: {
		maxGDriveEmails: {
			type: Number,
			default: 0,
		},
		gDriveEmails: {
			type: Array,
			default() {
				return []
			},
		},
	},
	data() {
		return {
			valid: false,
			dialog: false,
			loadingRemoval: false,
			newGDriveEmail: '',
			rules: {
				newGDriveEmail: [
					(v) => !!v || 'Email is required.',
					(v) => !v.includes('@') || 'Email should include only one @ symbol.',
					(v) => (v && v.length <= this.emailMaxLength) || `Name must be less than ${this.emailMaxLength} characters`,
				],
			},
			addGDriveEmailButtonText: this.$t('settings.add'),
			action: {
				started: false,
				finished: false,
			},
		}
	},
	computed: {
		addGDriveEmailButtonColor() {
			if (this.action.finished && this.action.success) {
				return 'success'
			} else if (this.action.finished && !this.action.success) {
				return 'warning'
			} else {
				return 'primary'
			}
		},
		emailMaxLength() {
			return 128
		},
		...mapGetters({
			currentUser: 'user/currentUser',
			setupInfo: 'user/setupInfo',
		}),
	},
	created() {
		if (!this.setupInfo.setup.gDriveEmail && this.currentUser.email.endsWith('@gmail.com')) {
			this.newGDriveEmail = this.currentUser.email
		}
	},
	methods: {
		callRemoveGDriveEmail(gDriveEmail) {
			this.loadingRemoval = true
			this.removeGDriveEmail(gDriveEmail.id).then(() => {
				this.dialog = false
				this.loadingRemoval = false
			})
		},
		callAddGDriveEmail() {
			this.$refs.form.validate()
			if (!this.valid) return
			if (!this.action.started) {
				this.action.started = true
				this.addGDriveEmailButtonText = this.$t('search.loading')
				this.addGDriveEmail(`${this.newGDriveEmail}@gmail.com`)
					.then(({ success }) => {
						if (success) {
							this.addGDriveEmailButtonText = this.$t('settings.done')
							this.newGDriveEmail = ''
							this.$refs.form.resetValidation()
						} else {
							this.action.started = false
							this.addGDriveEmailButtonText = this.$t('settings.tryAgain')
						}
					})
					.catch(() => {
						this.addGDriveEmailButtonText = 'Error'
					})
					.then(() => {
						this.action.finished = true
					})
			}
		},
		...mapActions('gdriveemails', ['addGDriveEmail', 'removeGDriveEmail']),
	},
}
</script>
